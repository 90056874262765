import '../styles/styles.scss';

import '@babel/polyfill';

// Config
import {configurationProfiles} from 'config/configurationProfiles'
import {configurationProfiles as configurationProfilesDevOverrides} from 'config/dev-overrides'
import {Configurations} from 'devtag-aurelia-config-plugin'

// Auth
import {OidcBackend, IdentityProvider} from "devtag-aurelia-auth-plugin";
import {ApiClient} from 'api-client';
import Backend from "backend";

import {I18N} from 'aurelia-i18n';
import I18NXhrBackend from 'i18next-xhr-backend';
import {ValidationMessageProvider} from 'aurelia-validation';
import {Router} from 'aurelia-router';

import numeral from 'numeral';
import 'numeral/locales/no';
import {HTMLSanitizer} from "aurelia-templating-resources";
import {BetterHTMLSanitizer} from "./BetterHTMLSanitizer";
import {RToastErrorRenderer} from "./util/r-toast-error-renderer";
import {LoggingErrorRenderer} from "./util/logging-error-renderer";
import {EventAggregator} from "aurelia-event-aggregator";
import {RMatomo} from "./util/r-matomo";
import {RaagoAppErrorComponent} from "./raago-app-error-component";
import * as dayjs from 'dayjs'
import 'dayjs/locale/nb' // import locale
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

function deleteAllCookies() {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
}

// Mostly to get rid of old _ga and fbpixel cookies, but also we do not use cookies except for oidc which I think has a different path and is therefore not included here.
deleteAllCookies();

export async function configure(aurelia) {
  // Set dayjs locale (date formatting)
  dayjs.locale('nb');
  dayjs.extend(LocalizedFormat)

  // Set the numeraljs locale
  numeral.locale('no');

  // Override validation messages, to have translation
  ValidationMessageProvider.prototype.getMessage = function(key) {
    const i18n = aurelia.container.get(I18N);
    const translation = i18n.tr(`validationErrorMessages.${key}`);
    return this.parser.parse(translation);
  };

  ValidationMessageProvider.prototype.getDisplayName = function(propertyName, displayName) {
    if (displayName !== null && displayName !== undefined) {
      return displayName;
    }
    const i18n = aurelia.container.get(I18N);
    return i18n.tr(propertyName);
  };

  // Configure Aurelia
  await aurelia.use
    .standardConfiguration()
    .developmentLogging()
    .plugin(PLATFORM.moduleName('devtag-aurelia-config-plugin'), config => config.profiles([configurationProfiles, configurationProfilesDevOverrides]))
    .plugin(PLATFORM.moduleName('devtag-aurelia-error-handling-plugin'), config => {
      // Give error handlers a chance to handle the error before rendering it
      config.addHandler(e => RaagoAppErrorComponent.handleError(aurelia, e));

      // Frontend renderer
      let rToastErrorRenderer = aurelia.container.get(RToastErrorRenderer);
      config.addRenderer(rToastErrorRenderer);

      // Log-to-backend renderer
      let loggingErrorRenderer = aurelia.container.get(LoggingErrorRenderer);
      config.addRenderer(loggingErrorRenderer);

      const i18n = aurelia.container.get(I18N);
      config.setErrorMessageProvider(new class {
        getMessage(key) {
          return i18n.tr('errorHandler.errorMessages.' + key);
        }
      })
    })
    .plugin(PLATFORM.moduleName('devtag-aurelia-auth-plugin'), config => {
      const router = aurelia.container.get(Router);
      const configurations = aurelia.container.get(Configurations);

      const authorityUrl = configurations.getConfig('authAuthorityUrl');
      const appBaseUrl = configurations.getConfig('authAppBaseUrl');

      config.authBackend = new OidcBackend(authorityUrl, appBaseUrl, appBaseUrl, router);

      // This callback fires when _everything_ is done. It's loaded even if additional claims weren't set. But not if no user is logged in.
      config.userLoadedCallback = user => {
        // Set token on api client.
        const apiClient = aurelia.container.get(ApiClient);
        apiClient.setAccessToken(user.access_token);
      };

      // Signout
      config.userSignedOutCallback = _ => {
        // Clear token from api client
        const apiClient = aurelia.container.get(ApiClient);
        apiClient.clearAccessToken();

          // Redirect to home
          router._configuredPromise.then(() => {
            // Disableing this too because it interupted the call to /endsession so that logout didn't work.
            // Can't remember why we needed it in the first place. We seem to redirect to login by other mechanism anyway.
            // And if the user clicked the logout button he shouldn't be sent to login again anyway. Maybe in Brygg?
            //// If trying to navigate to auth only -> redirect to login
            //// This is for when a user opens a page and it finds a stale token.
            // if (router.currentInstruction && router.currentInstruction.config.authOnly) {
            //   let url = router.generate(router.currentInstruction.config.name, router.currentInstruction.params);
            //   config.authBackend.redirectToLogin(url);
            // }

            // Disabled because a user with expired token hitting a landing page should not be sent to 'home'.
            // my-page seems to redirect to login anyway, so probably noone will se a permission denied page.
            // router.navigateToRoute('home');
          });
      };

      config.urlToRedirectToAfterLogin = '#/my-page';
      config.authorizedHomeRoute = '';
      config.noPermissionRoute = 'no-permission';
    })

    // Enable Aurelia Validation pluging
    .plugin(PLATFORM.moduleName('aurelia-validation'))

    // Enable Aurelia i18n plugin
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
      // Register backend plugin
      instance.i18next.use(I18NXhrBackend);

      // Configure i18next
      return instance.setup({
        lng: 'no',
        fallbackLng: 'en'
      });
    })

    // Plugin: scroll history
    .plugin(PLATFORM.moduleName('util/scroll-history-plugin'))

    // Register Devtag UI components for global use
    .feature(PLATFORM.moduleName('devtag-aurelia-components-plugin/index'))
    .globalResources(PLATFORM.moduleName('components/r-grid.html'))
    .globalResources(PLATFORM.moduleName('components/raago-fixed-bar'))
    .globalResources(PLATFORM.moduleName('components/raago-container'))
    .globalResources(PLATFORM.moduleName('components/raago-spacing'))
    .globalResources(PLATFORM.moduleName('components/raago-img-section'))
    .globalResources(PLATFORM.moduleName('components/raago-img-banner-section'))
    .globalResources(PLATFORM.moduleName('util/translate-enum-value-converter'))
    .globalResources(PLATFORM.moduleName('util/numeral-two-decimals-value-converter'))
    .globalResources(PLATFORM.moduleName('util/integer-value-converter'))
    .globalResources(PLATFORM.moduleName('util/date-value-converter'))
    .globalResources(PLATFORM.moduleName('util/date-short-value-converter'))
    .globalResources(PLATFORM.moduleName('util/date-time-value-converter'))
    .globalResources(PLATFORM.moduleName('util/week-number-value-converter'))
    .singleton(HTMLSanitizer, BetterHTMLSanitizer)

    // Google Analytics
    .postTask(() => {
      // Get configured tracking id
      const configurations = aurelia.container.get(Configurations);
      let gaTrackingId = configurations.getConfig("gaTrackingId");

      if (gaTrackingId) {
        // Load Google analytics onto global window.ga() function
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        // Configure trackingId (https://developers.google.com/analytics/devguides/collection/analyticsjs/creating-trackers)
        window.ga('create', gaTrackingId, 'auto');

        // Subscribe to router: send pageviews to google analytics
        const eventAggregator = aurelia.container.get(EventAggregator);
        eventAggregator.subscribe('router:navigation:success', event => {
          const props = {
            page: event.instruction.fragment,
            title: event.instruction.config.title,
            // anonymizeIp: true
          };
          console.log(props);
          window.ga('set', props);
          window.ga('send', 'pageview');
        });
      }
    })

    // Facebook Pixel
    .postTask(() => {
      // Get configured pixel id
      const configurations = aurelia.container.get(Configurations);
      let fbPixelId = configurations.getConfig("fbPixelId");

      if (fbPixelId) {
        // Load facebook pixel global fbq function
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');

        // Configure
        fbq('init', fbPixelId);

        // Subscribe to router: send pageviews to facebook
        const eventAggregator = aurelia.container.get(EventAggregator);
        eventAggregator.subscribe('router:navigation:success', event => {
          fbq('track', 'PageView');
        });
      }
    })

    // Matomo analytics
    .postTask(() => {
      // Config
      const configurations = aurelia.container.get(Configurations);
      const trackerUrl = configurations.getConfig('matomoTrackerUrl');
      const siteId = configurations.getConfig('matomoSiteId');

      // Don't start Matomo if trackerurl is missing.
      if (!trackerUrl || !siteId) {
        return;
      }

      // Checkout flow has internal steps and will do tracking on it's own.
      RMatomo.doNotAutoTrackUrlWithFragment('/checkout-flow');

      // White list routes that have UUIDs that don't need to be anonymized.
      RMatomo.addWhiteListItem('/product/');
      RMatomo.addWhiteListItem('/recipe/');
      RMatomo.addWhiteListItem('/supplier/');

      // Start tracking
      const eventAggregator = aurelia.container.get(EventAggregator);
      RMatomo.startTracking(trackerUrl, siteId, eventAggregator);
    })

    // Maintenance mode
    .postTask(() => {
      // Resolve api client
      const apiClient = aurelia.container.get(ApiClient);

      // Set a decorator on apiclient
      apiClient.setDecorator(promise => {
        // The decorator should catch any exception and inspect it
        return promise.catch(error => {
          // If it is maintenance mode => navigate to that page
          if (error.exceptionName === 'MaintenanceModeException') {
            const router = aurelia.container.get(Router);
            let nextUri = router.generate('home', {}, { absolute: true });
            window.location = 'maintenance-mode.html?next=' + encodeURIComponent(nextUri);
            throw error;

          } else {
            // If it's not maintenance mode => rethrow
            throw error;
          }
        })
      });
    });

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  await aurelia.start();
  await aurelia.setRoot(PLATFORM.moduleName('app'));
}
