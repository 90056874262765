import {inject} from 'aurelia-framework';
import Backend from "../backend";
import {activationStrategy, RedirectToRoute} from 'aurelia-router';
import {redirectToActiveSeoKey} from "../util/redirect-to-active-seo-key";
import {MetaTags} from "../util/meta-tags";

@inject(Backend)
export class ProductScreen {
  backend;

  visibleBundleConfiguration;

  constructor(backend) {
    this.backend = backend;
  }

  canActivate(params, routeConfig) {
    return this.backend.ProductDetailsQueryHandler_handle({productIdOrSeoKey: params.productIdOrSeoKey, maxAdditionalWeekCount: 2})
        .then(result => {
          this.product = result;

          // Enforce crates only because the url is "matkasser"
          if (!this.product.isCrate) {
            throw new Error('This screen can show only crate products. productIdOrSeoKey: ' + params.productIdOrSeoKey);
          }

          // SEO: Title
          routeConfig.navModel.setTitle(this.product.name + ' | ' + routeConfig.title);

          // SEO: Meta tags
          MetaTags.setMetaTags_rememberToCallCleanOnUnbind(this.product.descriptionMetaTag);

          // SEO: structured data
          let structuredData = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.product.nameAndUnit,
            "image": this.product.imageUrl,
            "description": this.product.description,
            "brand": {
              "@type": "Brand",
              "name": "Raago"
            },
            "offers": {
              "@type": "Offer",
              "price": this.product.priceIncVat,
              "priceCurrency": "NOK",
              "availability": "https://schema.org/InStock"
            }
          };
          this.productStructuredDataJson = JSON.stringify(structuredData);

          //
          if (this.product.bundleConfigurations.length > 0) {
            this.showBundleConfigurationIndex(0);
          }

          // SEO: handle seokey
          return redirectToActiveSeoKey(params, routeConfig, 'productIdOrSeoKey', this.product.seoKey);
        });
  }

  activate() {
    return Promise.all([
      this.backend.CrateCatalogQueryHandler_handle()
        .then(result => this.otherCrateProducts = result.items),
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "home__section3_1_html"},
            {cmsFragmentKey: "home__section3_2_html"},
            {cmsFragmentKey: "home__section3_3_html"},
            {cmsFragmentKey: "home__section3_1_image"},
            {cmsFragmentKey: "home__section3_2_image"},
            {cmsFragmentKey: "home__section3_3_image"},
          ]})
        .then(result => {
          this.cmsFragments = result;
        })
    ]);
  }

  unbind() {
    MetaTags.cleanMetaTags();
  }

  showBundleConfigurationIndex(i) {
    this.visibleBundleConfigurationIndex = i;
    this.visibleBundleConfiguration = this.product.bundleConfigurations[this.visibleBundleConfigurationIndex];
  }

  showNextBundleConfiguration(increment) {
    this.showBundleConfigurationIndex(this.visibleBundleConfigurationIndex + increment);
  }

  // When we retry and it fails again we need to reload this screen with new data, not just keep the view model we have.
  determineActivationStrategy() {
    return activationStrategy.replace;
  }
}
